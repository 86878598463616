<template>
  <v-app>
    <v-app-bar
      app
      height="60"
      dense
      :color="isDark ? 'rgba(0,0,0,.7)':'rgba(0,0,0,.0)'"
      class="app-bar"
    >
      <v-row class="width-per-100 height-per-100 ma-0 justify-center">
        <div class="width-per-100 height-per-100 d-flex justify-space-between menu">
          <div style="width: 260px;">
            <v-btn
                plain elevation="0"
                height="100%"
                min-width="100"
                to="/"
                class="pl-0 logo-btn"
                :ripple="false"
            >
              <v-img
                  alt="logo"
                  contain
                  src="https://h5.ophyer.cn/official_website/other/metaverse-logo.png"
                  height="30"
                  width="192"
              />
            </v-btn>
          </div>
          <div class="d-flex justify-end width-per-100">
            <v-hover
                v-for="(link,index) in links"
                :key="index">
              <div class="height-per-100 d-inline mx-6">
                <v-btn
                    height="100%"
                    light depressed plain
                    :color="currentMenu === link.router ? '#0568FD':''"
                    :ripple="false"
                    class="px-0"
                    :class="currentMenu === link.router ? 'menu-checked':''"
                    @click.stop="handleMenuClick(link, false, link)"
                >
                  <div class="menu-title">{{ link.title }}
                    <div :class="currentMenu === link.router ? 'menu-btn-active':''"></div>
                  </div>
                </v-btn>
              </div>
            </v-hover>
          </div>
        </div>
      </v-row>
    </v-app-bar>
    <!-- height:calc(100vh - 110px); -->
    <v-main
    style="overflow:auto;padding: 0;width:100vw;"
    :class="isDetail == true ?'main-margin devmain':'devmainhei'"
    v-scroll.self="onScroll">
      <router-view></router-view>
      <v-footer
        color="#111214"
        height="50"
        width="100%"
        class="justify-center align-start"
      >
        <div class="footer-box">
          <ul>
            <li @click="goto">开发者</li>
            <li @click.stop="$router.push('/develop/Experience')">教程</li>
            <li @click="dialog = true">API</li>
          </ul>
          <p>© COPYRIGHT 2021 . ALL RIGHTS RESERVED. 北京掌中飞天科技股份有限公司版权所有 京公网安备11010502037637号 京ICP09083236号</p>
        </div>
     </v-footer>
    </v-main>
    

    <div class="dialog" v-show="dialog" @click.stop="dialog = false">
      <transition name="fade" mode="out-in">
        <div class="content-tryout" @click.stop>
          <v-img
            contain
            src="https://h5.ophyer.cn/official_website/other/metaverse-diaog-api.png"
            height="100%"
          >
            <v-card-title class="justify-end pb-0 close-btn">
              <img width="11px" height="12px"  @click.stop="dialog = false" src="https://h5.ophyer.cn/official_website/other/metaverse-diaog-clone.png" alt="">
            </v-card-title>
          </v-img>
        </div>
      </transition>
    </div>
  </v-app>
</template>

<script>

import { mapState } from "vuex";

export default {
  name: 'develop',
  data: () => ({
    isDark: false,
    links: [
      {
        title: "首页",
        router: "/develop/Home",
        children: []
      },
      {
        title: "教程",
        router: "/develop/Experience",
        children: []
      },
      {
        title: "API",
        router: "/develop/API",
        children: [],
      },
    ],
    currentMenu: "/develop/Home",
    dialog: false,
    isDetail: false
    
  }),
  computed: {
    ...mapState(["officialWebsite"]),
  },
  watch: {
    $route(to) { 
      this.currentMenu = to.path;
    },
  },
  mounted() {
    let _this = this;
    document.addEventListener("click",()=>{
      _this.currentHover = '';
    })
    this.currentMenu = this.$route.path;
    const menuInfo = this.$route.path.split("/");
    if(menuInfo[2] === 'ExperienceDetail'){
      this.currentMenu = '/develop/Experience'
    }
    this.isDetail = this.$route.path.split("/")[2] == 'ExperienceDetail'
    console.log(this.isDetail)

  },
  methods: {
    goto(){
      this.$router.push('/develop/Home')
    },
    onScroll: function(e){
      this.isDark = e.target.scrollTop > 0;
    },
    handleMenuClick: function (menu, clickChildren, content){
      if(menu.title === "API") {
        this.dialog = true
        return false
      } 
      if(menu.children.length !== 0 && !clickChildren) return;
      if(content && content.router){
        if(content.isNewWindow){
          window.open(window.location.protocol + "//" + window.location.host + content.router)
        }else {
          this.$router.push(content.router)
        }
      }
      this.showMenuChildren = false;
    }

  }
};
</script>


<style lang="scss" scoped>
.v-application {
  background: #191b1f !important;
}
::v-dep app-bar{
  display: none !important;
}
.main-margin{
  margin: 60px 0 50px 0
}
.app-bar{
  line-height: 1;
  box-shadow: 0px 10px 20px 0px rgba(0, 114, 255, 0.1) !important;
  box-shadow: none !important;
  z-index: 1000 !important;
  ::v-deep .v-toolbar__content, .v-toolbar__extension {
    padding: 0 !important;
  }
  .v-toolbar__content {
    padding: 0 80px !important;
    .logo-btn{
      padding-left: 60px !important;
      .v-btn__content{
        opacity: 1 !important;
        height: 100%;
      }
    }
    .menu{
      margin: 0 auto;
      padding-right: 60px;
      .v-btn:not(.v-btn--round).v-size--default{
        min-width: unset;
      }
      .v-btn__content {
        opacity: unset !important;
      }
      .d-inline:nth-last-child(1) {
        margin-right: 0 !important;
      }
    }
    .menu-hover {
      border-bottom: 2px solid #333333;
    }
    .menu-title {
      margin-top: 10px;
      font-size: 16px;
      height: 30px;
      position: relative;
      color: rgba(255, 255, 255, 0.7);
      .menu-btn-active{
        position: absolute;
        bottom: 0px;
        left: 50%;
        transform: translate(-50%);
        height: 3px;
        width: 30px;
        background-color: #fff;
      }
    }
    .menu-checked .menu-title {
      color: #fff !important;
    }
  }
}


.v-main {
  scrollbar-width: none !important;
}

.devmain {
  height:calc(100vh - 110px);
  margin: 60px 0 0px 0;
}
.devmainhei {
  height:calc(100vh - 110px);
  overflow-y: none !important;
}



.footer-box {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  ul {
    display: flex;
    li {
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.9);
      line-height: 22px;
      margin-right: 32px;
      cursor: pointer;
      position: relative;
      &:hover {
        border-bottom: 1px solid #979797;
      }
      &::after {
        content: "";
        position: absolute;
        right: -16px;
        top: 5px;
        width: 1px;
        height: 12px;
        z-index: 1;
        background: #fff;
      }
    }
  }
  p {
    font-size: 12px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.4);
    margin-bottom: 0;
  }
}


.dialog {
  position: fixed;
  z-index:1001;
  height: 100%;
  width: 100%;
  background: rgba(0,0,0,.7);
  .content-tryout{
    width: 480px;
    height: 307px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    box-shadow: 0px 0px 15px 5px rgb(0 114 255 / 10%);
    .close-btn {
      cursor: pointer;
    }
  }
}

</style>